import { getRedirectUrl } from "@99tech/shared-utils";
import { Box, Container, ContainerProps, keyframes } from "@mui/material";
import React, { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { Bg, BgBright, BgSm, BgSmBright } from "src/assets";
import { ENTER_SITE_URL, RefCodeMap } from "src/main/constants";
import { useFbcid } from "src/main/hooks";
import { createStyles, isMobile } from "src/main/utils";

interface ContentProps extends React.PropsWithChildren, ContainerProps {}

const Content: React.FC<ContentProps> = (props) => {
  const { children, ...containerProps } = props;
  const { fbcid } = useFbcid();
  const [params] = useSearchParams();

  const handleClickPage = useCallback(() => {
    const url = getRedirectUrl(ENTER_SITE_URL, fbcid, params, RefCodeMap);
    window.open(url, "_blank", isMobile() ? "width=full,height=full" : "");
  }, [fbcid, params]);

  return (
    <Box
      sx={styles.root}
      onClick={handleClickPage}
    >
      <Container
        {...containerProps}
        sx={styles.container}
        maxWidth="sm"
      >
        {children}
      </Container>
    </Box>
  );
};

const animatedBg = keyframes`
  0%, 100% {
    background-image: url(${Bg})
  },
  50% {
    background-image: url(${BgBright})
  }
`;
const animatedBgSm = keyframes`
  0%, 100% {
    background-image: url(${BgSm})
  },
  50% {
    background-image: url(${BgSmBright})
  }
`;

const styles = createStyles({
  root: {
    "@media (min-width: 780px)": {
      animation: `${animatedBg} 1.3s infinite`,
    },
    "@media (max-width: 780px)": {
      animation: `${animatedBgSm} 1.3s infinite`,
    },
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundColor: "black",
    minHeight: "100vh",
    cursor: "pointer",
  },
  container: {
    height: "100%",
  },
});

export default Content;
