import { AppBarProps, Box, BoxProps, Toolbar, styled } from "@mui/material";
import React, { useContext } from "react";
import { TopLoginButton, TopRegisterButton } from "src/assets";
import { AppContext } from "src/main/constants";
import useGtmTracker, { EvtAction } from "src/main/hooks/useGtmTracker";
import { createStyles, joinSx } from "src/main/utils";

interface TopBarProps extends AppBarProps {}

const LoginImgBox = styled(Box)<BoxProps<"a">>(() => {
  const winWidth = window.innerWidth;
  let newWidth = "90px";
  if (winWidth < 360) {
    newWidth = parseInt(Math.ceil(winWidth / 3) + "") + "px";
  }

  return {
    "@keyframes glowLogin": {
      "0%": {
        opacity: 1,
      },
      "50%": {
        opacity: 0.7,
      },
      "100%": {
        opacity: 1,
      },
    },
    img: {
      width: newWidth,
      animation: "glowLogin 2s ease infinite",
      transition: "2s",
    },
    display: "flex",
  };
});

const RegisterImgBox = styled(Box)<BoxProps<"a">>(() => {
  const winWidth = window.innerWidth;
  let newWidth = "90px";
  if (winWidth < 360) {
    newWidth = parseInt(Math.ceil(winWidth / 3) + "") + "px";
  }

  return {
    "@keyframes glowRegister": {
      "0%": {
        opacity: 0.7,
      },
      "50%": {
        opacity: 1,
      },
      "100%": {
        opacity: 0.7,
      },
    },
    img: {
      width: newWidth,
      animation: "glowRegister 2s ease infinite",
      transition: "2s",
    },
    display: "flex",
  };
});

const TopBar: React.FC<TopBarProps> = (props) => {
  const { sendEvent } = useGtmTracker();
  const { urls } = useContext(AppContext);
  const { loginUrl, registerUrl } = urls ?? {};

  return (
    <Toolbar
      disableGutters
      sx={joinSx(styles.toolbar, { height: "8vh" })}
    >
      {/* <img alt="top bar brand logo" src={BrandLogo} style={{ height: "100%", objectFit: "contain", }} /> */}
      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          pt: 1,
        }}
      >
        <LoginImgBox
          component="a"
          href={loginUrl}
          onClick={() => sendEvent(EvtAction.ClickLogin)}
          target="_blank"
        >
          <img
            alt="top login"
            src={TopLoginButton}
          />
        </LoginImgBox>
        <RegisterImgBox
          component="a"
          href={registerUrl}
          onClick={() => sendEvent(EvtAction.ClickRegister)}
          target="_blank"
        >
          <img
            alt="top register"
            src={TopRegisterButton}
          />
        </RegisterImgBox>
      </Box>
    </Toolbar>
  );
};

const styles = createStyles({
  toolbar: {},
});

export default TopBar;
