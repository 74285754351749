import { Box, Container } from "@mui/material";
import { createStyles } from "src/main/utils";

export default function PageDescription() {
  return (
    <Container
      sx={styles.root}
      maxWidth="sm"
    >
      <Box sx={styles.content}>
        <Box sx={styles.checkItem}>
          <Box sx={styles.checkDescription}>
            Welcome to EZJILI, the premier destination for sophisticated and engaging online gaming experiences. At
            EZJILI, we are committed to providing our users with a high-quality platform that caters to both casual
            gamers and serious enthusiasts alike. Our site offers an extensive selection of games that are designed to
            entertain, challenge, and inspire.
          </Box>
        </Box>

        <Box sx={styles.checkItem}>
          <Box sx={styles.checkDescription}>
            To begin your journey with us, simply use the EZJILI LOGIN feature. The EZJILI LOGIN process is streamlined
            and user-friendly, ensuring that you can access your account quickly and efficiently. Once logged in, you
            will have access to a personalized gaming experience that includes saved progress, tailored recommendations,
            and the ability to connect with a community of like-minded gamers.
          </Box>
        </Box>

        <Box sx={styles.checkItem}>
          <Box sx={styles.checkDescription}>
            Our commitment to excellence is evident in every aspect of EZJILI. We continuously update our game library
            to include the latest and most innovative titles, ensuring that there is always something new and exciting
            for our users. With the EZJILI LOGIN, you can effortlessly explore our vast array of games and enjoy a
            seamless gaming experience.
          </Box>
        </Box>

        <Box sx={styles.checkItem}>
          <Box sx={styles.checkDescription}>
            At EZJILI, we understand the importance of a secure and reliable platform. Our EZJILI LOGIN system is
            designed with the highest standards of security in mind, safeguarding your personal information and
            providing you with peace of mind as you enjoy our services. Your gaming experience is our top priority, and
            we strive to create an environment that is both safe and enjoyable.
          </Box>
        </Box>

        <Box sx={styles.checkItem}>
          <Box sx={styles.checkDescription}>
            In addition to our exceptional gaming offerings, EZJILI is dedicated to providing outstanding customer
            support. Our team of professionals is available to assist you with any questions or concerns you may have.
            Whether you need help with the EZJILI LOGIN process or have inquiries about our games, we are here to ensure
            that your experience with EZJILI is nothing short of excellent.
          </Box>
        </Box>

        <Box sx={styles.checkItem}>
          <Box sx={styles.checkDescription}>
            As a member of the EZJILI community, you will have access to exclusive content, special promotions, and the
            opportunity to participate in events that enhance your gaming experience. The EZJILI LOGIN feature allows
            you to stay connected with the latest updates and take full advantage of everything our platform has to
            offer.
          </Box>
        </Box>

        <Box sx={styles.checkItem}>
          <Box sx={styles.checkDescription}>
            We invite you to join EZJILI today and discover the unparalleled gaming experience that awaits you. With the
            EZJILI LOGIN, you can unlock a world of entertainment and engage with a community that shares your passion
            for gaming. Thank you for choosing EZJILI as your preferred gaming site. We look forward to providing you
            with a professional and enjoyable gaming environment.
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

const styles = createStyles({
  root: {
    margin: "0px -20px",
    textAlign: "center",
    fontWeight: 700,
    pt: 3,

    img: {
      width: "100%",
    },
  },

  content: {
    padding: "0px 20px 20px",
  },

  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    color: "#F7A926",
    textShadow: "2px 2px 2px #4A2407, -2px 2px 2px #4A2407, -1px -1px 0 #4A2407, 1px -1px 0 #4A2407;",

    img: {
      height: "38px",
      width: "auto",
    },
  },

  description: {
    lineHeight: "1.15",
    color: "#F7A926",
    fontSize: "30px",
    WebkitTextStroke: "1px #4A2407",
    textShadow: "2px 2px 2px #4A2407, -2px 2px 2px #4A2407, -1px -1px 0 #4A2407, 1px -1px 0 #4A2407;",
  },

  checkItem: {
    color: "#fff",
    textAlign: "left",
    margin: "24px 0",
  },

  checkTitle: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    fontSize: "18px",
    marginBottom: "8px",

    img: {
      width: "24px",
      marginRight: "8px",
    },
  },

  checkDescription: {
    fontSize: "14px",
    fontWeight: 400,
  },
});
