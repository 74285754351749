import { VisuallyHidden } from "@99tech/ui";
import { Box, BoxProps, styled } from "@mui/material";
import React from "react";
import {
  EnterSite,
  GameCard1,
  GameCard2,
  GameCash,
  GameCrown,
  GameGirl,
  GameLucky,
  GameTaxi,
  GameTotem,
  Logo,
} from "src/assets";
import { PageDescription } from "src/main/components";
import { createStyles } from "src/main/utils";

interface LandingPageProps extends React.PropsWithChildren {}

const FlexImageBox = styled(Box)<BoxProps>(() => ({
  img: {
    maxHeight: "100%",
    maxWidth: "300px",
  },
  justifyContent: "center",
  display: "flex",
}));

const LandingPage: React.FC<LandingPageProps> = (props) => {
  return (
    <Box sx={styles.root}>
      <VisuallyHidden as="h1">Ezjili</VisuallyHidden>
      <FlexImageBox>
        <img
          alt="ezjili brand logo"
          src={Logo}
        />
      </FlexImageBox>
      <FlexImageBox>
        <img
          alt="enter site"
          src={EnterSite}
          style={{ width: "200px" }}
        />
      </FlexImageBox>
      <Box sx={styles.images}>
        <img
          alt="game card"
          src={GameCard1}
          style={{ animation: "gamecard1 4s infinite", top: -10, left: 60 }}
        />
        <img
          alt="game card"
          src={GameCard2}
          style={{ animation: "gamecard2 4s infinite", top: -10, right: 60 }}
        />
        <img
          alt="game crown"
          src={GameCrown}
          style={{
            animation: "gamecrown 1.3s infinite",
            bottom: 120,
            left: 0,
            right: 0,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <img
          alt="game cash"
          src={GameCash}
          style={{ animation: "gamecash 1.3s infinite", bottom: 50 }}
        />
        <img
          alt="game lucky"
          src={GameLucky}
          style={{
            animation: "gamelucky 1.3s infinite",
            right: 30,
            bottom: 60,
          }}
        />
        <img
          alt="game girl"
          src={GameGirl}
          style={{ animation: "gamegirl 1.3s infinite", bottom: 50 }}
        />
        <img
          alt="game totem"
          src={GameTotem}
          style={{
            animation: "gametotem 1.3s infinite",
            right: 0,
            bottom: 40,
          }}
        />
        <img
          alt="game taxi"
          src={GameTaxi}
          style={{
            animation: "gametaxi 1.3s infinite",
            bottom: 0,
            left: 0,
            right: 0,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      </Box>
      <PageDescription />
    </Box>
  );
};

const styles = createStyles({
  root: {
    pt: "20px",
    pb: "40px",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
  },
  images: {
    position: "relative",
    width: "360px",
    height: "400px",
    img: {
      position: "absolute",
    },
  },
});

export default LandingPage;
