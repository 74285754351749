import { useLayoutEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { DEFAULT_URL, FallbackRefCode, RefCodeMap } from "../constants";
import { addParams } from "../utils";
import useFbcid from "./useFbcid";

const useUrls = () => {
  const [urls, setUrls] = useState(["", "", FallbackRefCode[0]]);
  const [params] = useSearchParams();
  const { fbcid } = useFbcid();

  useLayoutEffect(() => {
    const hostname = window.location.hostname.replace(/^www\./, "");
    const [refCode, pixelId] = RefCodeMap[hostname] ?? FallbackRefCode;

    const code = params.has("code") ? params.get("code") ?? refCode : refCode;
    const pixel = params.has("pixel") ? params.get("pixel") ?? pixelId : pixelId;

    const urlParams = {
      id: code,
      fb_dynamic_pixel: typeof pixel === "string" ? pixel : undefined,
      fbcid: fbcid ? fbcid : undefined,
    };

    const loginUrl = addParams(DEFAULT_URL, { ...urlParams, action: "login" });
    const registerUrl = addParams(DEFAULT_URL, {
      ...urlParams,
      action: "register",
    });

    setUrls([loginUrl, registerUrl, code]);
  }, [params, fbcid]);

  return {
    loginUrl: urls[0] as string,
    registerUrl: urls[1] as string,
    refCode: urls[2] as string,
  };
};

export default useUrls;
